<template>
  <div class="row pt-3 model-house" style="border:1px solid #0b5a85; background-color: #f3f6f8; border-radius: 12px;" >
    <div class="col-md-12">
      <div id="filter-card" class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
       <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 id="page-title" class="d-inline-block items-center">{{ $t('model_house.model_house') }}</h3>
      </div>
    </div>
    <div class="col-md-12" style="background-color: #f3f6f8;">
      <dashboard-box>
        <template v-slot:preview>
          <div class=" py-3" style="border:1px solid #0b5a85; background-color: #f3f6f8; border-radius: 12px;">
            <div class="row col-12 m-0 p-0">
              <div class="col-5 row mx-0 px-2">
                <custom-multi-select
                    :helper-text="$t('model_house.please_select_designer')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.modeler_user_id"
                    :not-list="true"
                    :options="userOptions"
                    :placeholder="$t('sample.multi_select')"
                    :title="$t('model_house.designer')"
                    input-width="120px"
                    name="modeler_user_id"
                    object-key="name"
                    width="120px"
                    style="font-size: 10px!important">
                </custom-multi-select>
              </div>

              <div class="col-5 row mx-0 px-2">
                <custom-multi-select
                    :helper-text="$t('sample.please_select_a_collection_sample')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.sample_id"
                    :not-list="true"
                    :options="sampleOptions"
                    :placeholder="$t('sample.multi_select')"
                    :title="$t('sample.sample')"
                    input-width="120px"
                    name="sample_id"
                    object-key="collection_number"
                    width="120px"
                >
                </custom-multi-select>
              </div>
            </div>
          </div>
          <div class="col-12 py-10 row mx-0 px-0 d-flex justify-content-center items-center" style="background-color: #f3f6f8;">
               <span
                   class=" green-border-1px py-3 d-flex justify-content-center items-center  rounded-full h-30px w-100px mx-2 text-sm cursor-pointer"
                   @click="$router.go(-1)">
                <span v-html="getIconByKey('icons.model_house.give_up', {
                        class: 'w-35px h-35px object-cover d-inline-block opacity-75',
                  })">
                </span>
                {{ $t('general.give_up').toUpperCase() }}
              </span>

                <span
                    :class="{'navy-blue-border-1px text-navy-blue': isValidToSubmit, 'navy-blue-border-disabled-1px text-disabled-navy-blue': !isValidToSubmit}"
                    class=" rounded-full d-flex justify-content-center items-center  mx-2 cursor-pointer h-30px w-100px text-sm"
                    @click="submitModelHouse"
                >
                        <span v-html="getIconByKey('icons.model_house.save', {
                                class: 'w-35px h-35px object-cover d-inline-block ' + (isValidToSubmit ? 'opacity-75' : 'opacity-45'),
                          })">
                        </span>
                        {{ $t('general.save').toUpperCase() }}
                    </span>
              </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import TextInput from "@/assets/components/inputs/TextInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {CREATE_ITEM, GET_ITEMS} from "@/core/services/store/REST.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "ModelHouseEntry",
  components: {
    ButtonWithIcon,
    TextInput,
    NumberInput,
    SelectInput,
    DashboardBox,
    CustomMultiSelect
  },
  computed: {
    ...mapGetters({}),
    isValidToSubmit() {
      if (Number(this.form.modeler_user_id) === 0) return false;
      if (Number(this.form.sample_id) === 0) return false;
      return true;
    }
  },
  data() {
    return {
      is_submitting: false,
      storeOptions: {
        '1': this.$t('model_house.fabric_depo'),
        '2': this.$t('model_house.accessory_depo'),
      },
      form: {
        modeler_user_id: null,
        sample_id: null,
      },
      userOptions: [],
      sampleOptions: [],
    };
  },
  methods: {
    ...mapMutations({}),
    submitModelHouse() {
      if (!this.isUserGranted('Staff', ['modelHouseChief'])) {
        return;
      }
      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      if (this.isValidToSubmit) {
        this.$store.dispatch(CREATE_ITEM, {
          url: 'api/model-house',
          acceptPromise: true,
          contents: this.form,
        }).then((result) => {
          if (result.status) {
            self.sweetAlertSuccess(self.$t('general.successfully_created')).then(_ => {
              this.is_submitting = false;
              self.$router.push({name: 'model_house.index'});
            })
          }
        })
      }
    },
    getModelHouseStaff() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/users/model-house-staff',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.userOptions = self.convertArrayToObjectByKey(result.data, 'id');
        }
      });
    },
    getSamples() {
      let self = this;
      let filters = {
        is_completed: 1,
        sample_type_id: 1,
        sample_status_id: 1
      }
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/samples',
        filters,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.sampleOptions = self.convertArrayToObjectByKey(result.data.data, 'id');
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("model_house.model_house")}
    ]);

    if (this.isUserGranted('Staff', ['modelHouseChief'])) {
      this.getModelHouseStaff();
      this.getSamples();

      if (this.$route.params.id) {
        this.form.sample_id = this.$route.params.id;
      }
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/model-house/modelHouseEntry.scss";
/*Mobile End*/
</style>
